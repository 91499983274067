<div class="col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
    <h2>ABOUT US</h2>

    <p>Welcome to K.Pipes and Fittings Centre, we have been servicing contractors, municipalities,
         schools, etc. in the business of installation and repair of commercial and residential plumbing 
         since 1996. K.Pipes and Fittings Centre has grown to have multiple fully stocked locations to cover
          the entire Kenya to provide our customers with the highest level of service possible.</p><br>
    <p>Servicing our customers is an integral part of what we do, we also know your time is valuable 
        so when you are picking up product at our locations, our experienced counter people will get 
        your order filled quickly and get you on your way! Our vehicles and experienced delivery team 
        are on the road every day. Communicating a delivery time to your shop or job site is just one 
        of the services we provide. In today´s market, contractors must be provided with customer service 
        to its´ fullest extent, being there when you need plumbing supplies 7 days a week, 24 hours a day, 
        having the product in stock and delivering when it is needed. As well as, providing technical 
        expertise, up-to-date training and plenty of literature and spec sheets to assist you.</p><br>
    <p>For more info, call our service number 0708883057 or visit one of our social pages (links on the top right corner)</p><br>
    <p>Our headquarters is at Kikuyu and we have branches in; </p>
    
    <ul>
        <div class="col-md-3 col-sm-3 col-xs-6">
            <li>Kikuyu - near stage</li>
            <li>Shop1 - along bypass</li>
            <li>Nanyuki</li>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-6">
            <li>Nyahururu</li>
            <li>Gilgil</li>
            <li>Nakuru</li>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-6">
            <li>Molo</li>
            <li>Naivasha</li>
            <li>Eldoret</li>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-6">
            <li>Bungoma</li>
            <li>Kisii</li>
            <li>Narok</li>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-6">
            <li>Ruaka</li>
            <li>Kiserian</li>
            <li>Nyeri</li>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-6">
            <li>Molo</li>
            <li>Rongai</li>
            <li>Bomet</li>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-6">
            <li>Thika</li>
            <li>Ruthigiti</li>
            <li>Muranga</li>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-6">
            <li>Maai Mahiu</li>
            <li>Ukunda</li>
            <li>Mtwapa</li>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-6">
            <li>Kisumu</li>
        </div>
    </ul><br>
</div>