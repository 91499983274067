import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gutters',
  templateUrl: './gutters.component.html',
  styleUrls: ['./gutters.component.css']
})
export class GuttersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
