<div class=" col-md-12 col-sm-12 col-xs-12 cont" align="center">
    <h3>Waste fittings</h3>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/waste/4 Way trap.webp "><br>4 Way Float Trap
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/waste/Cross Tee (2).webp"><br>Waste Tee
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/waste/Waste Bend 45.webp"><br>45° Waste Bend
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/waste/Inspection Bend (2).webp"><br>Inspection Bend
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/waste/Inspection Tee (2).webp"><br>Waste Tee
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/waste/Inspection Tee (3).webp"><br>Inspection Tee
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/waste/Vent cup (3).webp"><br>Vent Cup
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/waste/Vent Cup.webp"><br>Waste Plug
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/waste/Waste bend (2).webp"><br>90° Waste Bend
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/waste/Waste R bush.webp"><br>Waste R Bush
        </div>
    </div>
</div>