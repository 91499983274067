<div class="col-md-12 col-sm-12 col-xs-12 cont">
    <h2>PIPES</h2>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_122203_043.webp">Waste Pipes Brown
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_122301_905.webp">Waste Pipes White
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_121006_220.webp">Conduits
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_121053_394.webp">Ppr Pipes
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_121708_569.webp">Waste Pipe grey
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_121841_475.webp">Waste Pipes Brown
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_123614_864.webp">HDPE Roll
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_124006_371.webp">Double Wall Corrugated Pipe
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_120829_719.webp">Ppr pipes
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_120909_983.webp">Pressure Pipe  
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_120741_945.webp">Ppr pipes
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_121645_221.webp">Waste Pipe White
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_122101_596.webp">Waste Pipe Grey
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_121540_335.webp">Waste Pipe Grey
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_121259_803.webp">Pressure Pipe
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_121408_980.webp">Pressure Pipe
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/IMG_20210731_122012_305.webp">Waste Pipe Grey
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/pipes/pipe6.webp">Ppr Roll
        </div>
    </div>
</div>