import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OwlModule } from 'ngx-owl-carousel';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { UsComponent } from './us/us.component';
import { ProcutsComponent } from './procuts/procuts.component';
import { PipesComponent } from './procuts/pipes/pipes.component';
import { FittingsComponent } from './procuts/fittings/fittings.component';
import { TapsComponent } from './procuts/taps/taps.component';
import { GuttersComponent } from './procuts/gutters/gutters.component';
import { ContactsComponent } from './contacts/contacts.component';
import { FootComponent } from './foot/foot.component';
import { CeramicsComponent } from './procuts/ceramics/ceramics.component';
import { Waste2Component } from './procuts/fittings/waste2/waste2.component';
import { Gi2Component } from './procuts/fittings/gi2/gi2.component';
import { Hdpe2Component } from './procuts/fittings/hdpe2/hdpe2.component';
import { Ppr2Component } from './procuts/fittings/ppr2/ppr2.component';
import { TanksComponent } from './procuts/tanks/tanks.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UsComponent,
    ProcutsComponent,
    PipesComponent,
    FittingsComponent,
    TapsComponent,
    GuttersComponent,
    ContactsComponent,
    FootComponent,
    CeramicsComponent,
    Waste2Component,
    Gi2Component,
    Hdpe2Component,
    Ppr2Component,
    TanksComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OwlModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
