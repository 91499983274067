<div class="col-md-12 col-sm-12 col-xs-12">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

    <h2>CONTACTS</h2>

    <div class="col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
        <h3>Main - Kikuyu</h3>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <p><i class="fa fa-map-marker"></i>:Gatunyu Place, Ground Floor, Karuri, Kikuyu</p>          
            <p><i class="fa fa-phone"></i>:<a href="tel: +254-724002238">+254-724002238</a></p>    
            <p><i class="fa fa-phone"></i>:<a href="tel: +254-708-883057">+254-708-883057</a></p>
            <p><i class="fa fa-phone"></i>:<a href="tel: +254-725-468228">+254-725-468228</a></p>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <p><i class="fa fa-envelope"></i>: <a href=" mailto: info@kpipes.co.ke">info@kpipes.co.ke</a></p>
            <p><i class="fa fa-envelope"></i>: <a href=" mailto: sarah.muinamu@kpipes.co.ke">sarah.muinamu@kpipes.co.ke</a></p>           
            <p><i class="fa fa-envelope"></i>P.O Box 36-00902,
            Kikuyu, Kenya</p>  
        </div>
    </div>

    <div class="col-md-12 col-sm-12 col-xs-12">
        <iframe src="https://www.google.com/maps/d/embed?mid=1FA3qihLMhuiMu_42WTkgM5jqRcxZL_D_" width="90%" height="480"></iframe>
    </div>
    
    <div class="col-md-10 col-md-offset-1 col-sm-12 col-xs-12" style="padding: 10px;">
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Nakuru <i class="fa fa-phone"></i>: <a href="tel: +254-746-463-327">+254-746-463-327</a></p>
        </div>    
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Eldoret <i class="fa fa-phone"></i>: <a href="tel: +254-742-139-326">+254-742-139-326</a></p>
        </div>    
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Nyahururu <i class="fa fa-phone"></i>: <a href="tel: +254-768-009-868">+254-768-009-868</a></p>
        </div>    
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Molo <i class="fa fa-phone"></i>: <a href="tel: +254-700-557-411">+254-700-557-411</a></p>
        </div>    
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Thika <i class="fa fa-phone"></i>: <a href="tel: +254-715-285-035">+254-715-285-035</a></p>
        </div>    
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Kisii <i class="fa fa-phone"></i>: <a href="tel: +254-768-009-877">+254-768-009-877</a></p>
        </div>    
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Bomet <i class="fa fa-phone"></i>: <a href="tel: +254-783-826-841">+254-783-826-841</a></p>
        </div>    
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Maai Mahiu <i class="fa fa-phone"></i>: <a href="tel: +254-111-966-168">+254-111-966-168</a></p>
        </div>    
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Muranga <i class="fa fa-phone"></i>: <a href="tel: +254-722-698-927">+254-722-698-927</a></p>
        </div>    
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Narok <i class="fa fa-phone"></i>: <a href="tel: +254-722-755-089">+254-722-755-089</a></p>   
        </div> 
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Nyeri <i class="fa fa-phone"></i>: <a href="tel: +254-759-270-597">+254-759-270-597</a></p>
        </div>    
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Gilgil <i class="fa fa-phone"></i>: <a href="tel: +254-768-009-868">+254-768-009-868</a></p>
        </div>    
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Bungoma <i class="fa fa-phone"></i>: <a href="tel: +254-769-082-076">+254-769-082-076</a></p>
        </div>    
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Naivasha <i class="fa fa-phone"></i>: <a href="tel: +254-796-350-906">+254-796-350-906</a></p>
        </div>    
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Nanyuki <i class="fa fa-phone"></i>: <a href="tel: +254-796-350-904">+254-796-350-904</a></p>
        </div>    
        <div class="col-md-3 col-sm-4 col-xs-12">
                <p>Mtwapa <i class="fa fa-phone"></i>: <a href="tel: +254-712-784-868">+254-712-784-868</a></p>
        </div>    
    </div>
</div>