<div class="col-md-12 col-sm-12 col-xs-12 cont" align="center">
    <h2>FITTINGS</h2>
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="col-md-3 col-sm-3 col-xs-6">
            <button routerLink="/waste2"><img src="../../../assets/waste/Waste Fittings (3).webp"></button><br>Waste Fittings
        </div>
        <div class="col-md-3 col-sm-3 col-xs-6">
            <button routerLink="/ppr2"><img src="../../../assets/ppr fittings/pprfittings.webp"></button><br>Ppr Fittings
        </div>
        <div class="col-md-3 col-sm-3 col-xs-6">
            <button routerLink="/gi2"><img src="../../../assets/gi fittings/gifittings.webp"></button><br>Gi Fittings
        </div>
        <div class="col-md-3 col-sm-3 col-xs-6">
            <button routerLink="/hdpe2"><img src="../../../assets/hdpe fittings/hdpe8.webp"></button><br>HDPE Fittings
        </div>
    </div>
</div>