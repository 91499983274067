<div class="col-md-12 col-sm-12 col-xs-12 cont" align="center">
    <h3>Gi fittings</h3>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/gi elbow (3).webp">MF Bend
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/gi-union-pipe.webp">Gi Union
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/gi holder (3).webp">Clip
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/gi holder.webp">Clip
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/GI-Reducing-Bush.webp">Gi Reducing Bush
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/gi t.webp">Gi Tee
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/gi union (2).webp">Gi Socket
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/GI-Pipe.webp">Gi Pipe
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/long nipple.webp">Gi Long Nipple
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/short nipple.webp">Gi Short Nipple
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/gi fitting.webp">Gi End Plug
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/gi funnel.webp">Gi Reducing Socket
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/gi cap.webp">Gi End Cap
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/GI-Elbow.webp">Gi Elbow
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/gi fittings/gi screw (2).webp">Back Nut
        </div>
    </div>
</div>