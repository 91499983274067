<div class="col-md-12 col-sm-12 col-xs-12 cont">
        <h2>CERAMICS</h2>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/close couple2.webp">Close Couple Complete
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/close couple3.webp">Close Couple Complete
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/close couple.webp">Close Couple Complete
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_102957_789.webp">Close Couple Complete
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_113229_589.webp">Urinal Bowl
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_113327_651.webp">Corner Basin
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_114159_307.webp">S-trap Toilet
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_114322_529.webp">Close Couple
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_114350_917.webp">Close Couple
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_115312_431.webp">Bath Tub
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_115409_072.webp">Bath Tray
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_115817_199.webp">Ceramic Terrazzo Sink
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_115956_257.webp">Ceramic Rectangular Sink
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_123114_307.webp">Wash Hand Basin
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_123207_107.webp">Wash Hand Basin
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/p trap.webp">P-trap Toilet
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/s trap.webp">S-trap Toilet
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_105347_062.webp">Ceramic Tissue Holder
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_103759_118.webp">Step Asian Toilet
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_105319_062.webp">Ceramic Soap Dish
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_112203_042.webp">Wash Hand Basin
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_113141_682.webp">Ordinary Asian Toilet
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG-20210806-WA0014.webp">Close Couple Complete
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG-20210806-WA0015.webp">Close Couple Complete
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG-20210806-WA0016.webp">Close Couple Complete
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <img src="../../../assets/ceramics/IMG_20210813_124658_343.webp">Closet Wash Hand Basin
            </div>
        </div>
</div>