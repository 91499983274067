import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CeramicsComponent } from './procuts/ceramics/ceramics.component';
import { ContactsComponent } from './contacts/contacts.component';
import { FittingsComponent } from './procuts/fittings/fittings.component';
import { Gi2Component } from './procuts/fittings/gi2/gi2.component';
import { Hdpe2Component } from './procuts/fittings/hdpe2/hdpe2.component';
import { Ppr2Component } from './procuts/fittings/ppr2/ppr2.component';
import { Waste2Component } from './procuts/fittings/waste2/waste2.component';
import { GuttersComponent } from './procuts/gutters/gutters.component';
import { HomeComponent } from './home/home.component';
import { PipesComponent } from './procuts/pipes/pipes.component';
import { ProcutsComponent } from './procuts/procuts.component';
import { TapsComponent } from './procuts/taps/taps.component';
import { UsComponent } from './us/us.component';
import { TanksComponent } from './procuts/tanks/tanks.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home',  component: HomeComponent },
  { path:'us', component: UsComponent },
  { path: 'contacts', component: ContactsComponent },
  { path:'products', component: ProcutsComponent },
    { path:'pipes', component: PipesComponent},
    { path:'gutters', component: GuttersComponent },
    { path: 'ceramics', component: CeramicsComponent },
    { path:'taps', component: TapsComponent },
    { path:'tanks', component: TanksComponent },
    { path:'fittings', component:FittingsComponent },
      { path: 'waste2', component: Waste2Component },
      { path: 'ppr2', component: Ppr2Component },
      { path: 'gi2', component: Gi2Component },
      { path: 'hdpe2', component: Hdpe2Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
