<div class="col-md-12 col-sm-12 col-xs-12 cont" align="center">
    <h3>Ppr fittings</h3>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/male union.webp">Male Socket
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/ppr gate valve.webp">Ppr Gate Valvue
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/ppr tap.webp">Ppr Concealed Stop Corck
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/t female.webp">Ppr Female Tee
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/pprmale.webp">Female Thread Union
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/pprtap.webp">Ball Corck
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/t junction.webp">Ppr Tee
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/tmale.webp">Ppr Male Tee
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/IMG_20210624_123131_507.webp">Ppr Union
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/male elbow.webp">Ppr Male Elbow
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/elbow (2).webp">Ppr Elbow
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/elbow.webp">45° Ppr Elbow
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/female elbow.webp">Ppr Female Elbow
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/female union.webp">Female Socket
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/ppr fittings/union.webp">Socket
        </div>
    </div>
</div>