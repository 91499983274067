import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  homeSlider = { items: 1,
    autoplay: true, 
    autoplayTimeout: 2500,
    loop: true,
    dots: false
  }

  constructor() { }

  ngOnInit(): void {
  }
  

}
