<div class="row">
    <footer>
      <hr style="background: orangered; height: 2px; border-radius: 50%; width: 90%;">
      <p style="padding: 0px">@copyright 2021 all rights reserved</p>
      
      <div class="col-md-12 col-sm-12 col-xs-12" align=center>
        <div class="col-md-3 col-sm-4 col-xs-12">          
          <p><i class="fa fa-phone"></i>:
          <a href = "tel: +254-708-883057">+254-708-883057</a></p>
          <p><i class="fa fa-phone"></i>:
          <a href = "tel: +254-725-468228">+254-725-468228</a></p>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12">
            <p><a href="mailto: info@kpipes.co.ke" target="_blank"><i class="fa fa-envelope"></i>info@kpipes.co.ke</a></p>
            <p><a href="mailto: sarah.muinamu@kpipes.co.ke" target="_blank"><i class="fa fa-envelope"></i>sarah.muinamu@kpipes.co.ke</a></p>
        </div>
        <div class="col-md-2 col-sm-4 col-xs-12">
            <p><a href="https://twitter.com/kpipess" target="_blank"><i class="fa fa-twitter"></i>@kpipess</a></p>
            <p><a href="https://www.facebook.com/Kpipescentre/" target="_blank"><i class="fa fa-facebook"></i>@Kpipescentre</a></p>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12">
          <p><a href="https://www.instagram.com/k_pipes_and_fittings/" target="_blank">
            <i class="fa fa-instagram"></i>@k_pipes_and_fittings</a>
          </p>
          <p><a href="https://www.youtube.com/channel/UCRRivmSErZUOvKbLNmOSbCA" target="_blank">
            <i class="fa fa-youtube"></i>@Kpipes and Fittings Center Ltd</a>
          </p>
        </div>
      </div>
    </footer>
</div>