<div class="col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
    <owl-carousel [options]="homeSlider" [carouselClasses]="['owl-theme', 'customClassName']"> 
        <div class="item">
            <img src="../../assets/slide/logo.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/0W7A9985.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/IMG_4068.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/IMG_4112.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/DSC04432.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/DSC_4971.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/DSC_4952.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/DSC_4956.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/DSC_5054.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/DSC_5070.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/DSC_5207.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/DSC_5233.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/DSC_5336.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/DSC_5344.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/IMG_20210713_104855_619.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/IMG_20210713_104905_991.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/IMG_20210713_112354_105.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/IMG_20210713_115139_981.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/slide.webp">
        </div>
        <div class="item">
            <img src="../../assets/slide/IMG_20210624_132528_371.webp">
        </div>
    </owl-carousel>
</div>