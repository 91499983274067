<div class="col-md-12 col-sm-12 col-xs-12 cont">
    <h2>GUTTERS</h2>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter1.webp">Gutter Pipe
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter2.webp">Gutter Dropper
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter3.webp">Gutter Holder
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter4.webp">65°  Elbow
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter19.webp">Outside Corner
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter6.webp">Converter
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter7.webp">Connector
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter8.webp">90­° Elbow
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter9.webp">Pipe Socket
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter10.webp">Pipe Clip
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter12.webp">Drop Outlet
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter13.webp">Gutter Angle
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter14.webp">Inside Corner
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter15.webp">Equal Tee
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter16.webp">Gutter Hopper
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter17.webp">Gutter Box
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter18.webp">Gutter Box
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/gutters/gutter20.webp">Gutter End Cap
        </div>
    </div>
</div>