<div class="col-md-12 col-sm-12 col-xs-12 cont" align="center">
    <h3>Hdpe fittings</h3>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/hdpe fittings/hdpe2.webp">Hdpe Male End Plug
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/hdpe fittings/hdpe3.webp">Hdpe Ball Corck
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/hdpe fittings/hdpe6.webp">Hdpe Coupling
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/hdpe fittings/hdpe4.webp">Hdpe Elbow
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/hdpe fittings/hdpe.webp">Hdpe Tee
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/hdpe fittings/hdpe1.webp">Hdpe Female End Plug
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../../assets/hdpe fittings/hdpe5.webp">Hdpe Ball valve
        </div>
    </div>
</div>