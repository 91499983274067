<div class="col-md-12 col-sm-12 col-xs-12 cont">
    <h2>TAPS</h2>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap3.webp">Hose Pegular Tap
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap4.webp">Basin Mixer
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap5.webp">Kitchen Mixer
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap6.webp">Concealed Stop Cock
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap7.webp">Ball Cock
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap10.webp">Kitchen Mixer Double Wall
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap8.webp">Long Neck Wall Tap
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap16.webp">Angle Valve
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap11.webp">Ordinary Stop Cork
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap12.webp">Long Neck Wall Tap
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap1.webp">Concealed Stop Cork
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap2.webp">Long Basin Mixer
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap13.webp">Pillar Tap
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pen">
            <img src="../../assets/taps/tap14.webp">Sprout Blue Tap
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap14 (2).webp">Sprout Blue Tap
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap15.webp">Push Tap
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/taps/taps2.webp">Ordinary Tap
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/taps/taps3.webp">Lockable Tap
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/taps/tap17.webp">Flexible Mixer Tap
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/taps/gate valve.webp">Gate Valve
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
    </div>
</div>