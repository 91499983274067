<div class="container">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <meta name="viewport" content="width=device-width, initial-scale=1">

  <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 8px;">
    <h1 class="pad"><a routerLink="/">
      <div class="col-xs-12 col-sm-8 col-md-8"><img src="../assets/1 copy.png" width="120">K.PIPES & FITTINGS CENTRE</div></a>
      <div class="col-xs-12 col-sm-4 col-md-4">
        <a class="link" href="https://www.youtube.com/channel/UCRRivmSErZUOvKbLNmOSbCA" target="blank"><i class="fa fa-youtube"></i></a>
        <a class="link" href="https://www.instagram.com/k_pipes_and_fittings/" target="blank"><i class="fa fa-instagram"></i></a>
        <a class="link" href="https://www.facebook.com/Kpipescentre/" target="blank"><i class="fa fa-facebook"></i></a>
        <a class="link" href="https://twitter.com/kpipess" target="blank"><i class="fa fa-twitter"></i></a>
      </div>
    </h1>
  </div>
  
  <hr>
    
    <div class="col-md-12 col-sm-12 col-xs-12 pad">
      <nav>
        <a routerLink="/">Home</a>
        <a routerLink="/us">About Us</a>
        <a routerLink="/products">Products</a>
        <a routerLink="/contacts">Contact-us</a>
      </nav>
    </div>
  
  <hr>
    
  <div align="center">
    <router-outlet></router-outlet>
  </div>

    <app-foot></app-foot>
</div>