<div class="col-md-12 col-sm-12 col-xs-12 cont">
    <h2>PRODUCTS</h2>
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <button routerLink="/pipes"><img src="../../assets/pipes/pipe9.webp"><br>Pipes</button>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <button routerLink="/fittings"><img src="../../assets/ppr fittings/pprfittings.webp"><br>Fittings</button>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <button routerLink="/taps"><img src="../../assets/taps/tap12.webp"><br>Taps</button>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <button routerLink="/tanks"><img src="../../assets/tanks/tank.webp"><br>Tanks</button>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 pen">
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <button routerLink="/gutters"><img src="../../assets/gutters/gutter1.webp"><br>Gutters</button>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 pan">
                <button routerLink="/ceramics"><img src="../../assets/ceramics/p trap.webp"><br>Ceramics</button>
            </div>
        </div>
    </div>
</div>