<div class="col-md-12 col-sm-12 col-xs-12 cont">
    <h2>TANKS</h2>
    <div class="col-md-12 col-sm-12 col-xs-12">
        <img src="../../../assets/tanks/IMG_20201210_112213_218.webp">
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/tanks/tank2.webp">Loft Tank
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/tanks/tank3.webp">Loft Poa Tank
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/tanks/IMG_20210731_122557_698.webp">Vectus Tank
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/tanks/IMG_20210731_123023_092.webp">Poa Tank
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/tanks/tank4.webp">Roto Tank
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../../assets/tanks/IMG_20210731_122456_895.webp">Techno Tank
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 pen">
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/tanks/tank.webp">Kentank
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pan">
            <img src="../../assets/tanks/tank1.webp">Vectus/Styro Tank
        </div>
    </div>
</div>